import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часті запитання про оренду яхт Petadazen
			</title>
			<meta name={"description"} content={"Впевнено йти в море - ваші запити, наші морські рішення"} />
			<meta property={"og:title"} content={"FAQ | Часті запитання про оренду яхт Petadazen"} />
			<meta property={"og:description"} content={"Впевнено йти в море - ваші запити, наші морські рішення"} />
			<meta property={"og:image"} content={"https://ua.petadazen.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ua.petadazen.com/img/4523525325.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.petadazen.com/img/4523525325.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.petadazen.com/img/4523525325.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="#ffffff" quarkly-title="FAQ-9">
			<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--darkL1" sm-margin="0px 0px 50px 0px">
				Часті запитання
			</Text>
			<Text
				margin="0px 0px 80px 0px"
				font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
				color="#555a5f"
				width="80%"
				md-width="100%"
				md-padding="0px 0px 0px 0"
			>
				Готуючись до розкішної подорожі з Petadazen, ми зібрали відповіді на деякі поширені запитання, щоб ваш морський досвід був таким же плавним, як хвилясті хвилі під вашою яхтою.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="1fr"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
				flex-direction="column"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Які типи яхт ви пропонуєте для оренди?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Наш флот складається з елегантних яхт, кожна з яких ретельно розроблена, щоб запропонувати унікальний і розкішний досвід морської подорожі.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи можу я забронювати яхту для особливої події?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Безумовно! Наші ексклюзивні чартерні послуги ідеально підходять для того, щоб перетворити святкування на незабутні моменти на тлі відкритого моря.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи передбачено харчування на борту?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Побалуйте себе вишуканими стравами від наших бортових шеф-кухарів, які розробляють меню, що відповідає величі вашої яхтової подорожі.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Чи можу я спланувати індивідуальний маршрут для своєї подорожі на яхті?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Безумовно! Наша команда готова допомогти вам налаштувати вашу морську пригоду, незалежно від того, чи це відпочинок на вихідні, чи тривале дослідження.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						На які зручності я можу розраховувати на яхті?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Від просторих інтер'єрів, прикрашених найкращими меблями, до професійного екіпажу, який забезпечить безперебійну подорож, Petadazen обіцяє розкішний і комфортний досвід.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					padding="25px 0px 0px 0px"
					border-width="1px 0 0 0"
					border-style="solid"
					border-color="--color-lightD2"
					md-flex-direction="column"
				>
					<Text
						margin="0px 0px 15px 0px"
						font="normal 500 25px/1.2 --fontFamily-sans"
						color="--darkL1"
						width="40%"
						md-width="100%"
					>
						Як забронювати яхту для своєї подорожі?
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
						color="#555a5f"
						width="60%"
						padding="0px 0px 0px 50px"
						md-width="100%"
						md-padding="0px 0px 0px 0"
					>
						Зв'яжіться з нашою командою по телефону або електронною поштою, щоб почати планувати свою морську подорож. Ми тут, щоб допомогти вам відправитися у вашу надзвичайну подорож.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contact />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});